import { Box, Typography, useTheme } from "@material-ui/core";
import React from "react";
import Imgix from "react-imgix";
import { POD_KEYS_COUNT } from "../../constants";
import { Helmet } from "react-helmet";

type Props = {
  podKey: string;
  productTitle?: string;
  brandName?: string;
  className?: string;
  withPodImage?: boolean;
};

export default function PodKeyInfo({ podKey, className, withPodImage }: Props) {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={className}
      >
        {withPodImage && (
          <Imgix
            src="https://www.datocms-assets.com/15671/1617121173-pod-info-noblur.png"
            width={106}
            height={183}
            imgixParams={{
              ar: "1",
              auto: "format,compress"
            }}
          />
        )}

        {/* Pod code */}
        <Typography
          component="div"
          style={{
            width: "123px",
            height: "66px",
            marginTop: "-70px",
            paddingLeft: "8px",
            letterSpacing: "8px",
            fontSize: "24px",
            fontFamily: "'Roboto Mono', monospace",
            backgroundColor: theme.palette.grey[100],
            borderRadius: "12px",
            border: `3px solid ${theme.palette.primary.main}`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            userSelect: "none"
          }}
        >
          {podKey?.substring(0, POD_KEYS_COUNT / 2)}
          <br />
          {podKey?.substring(POD_KEYS_COUNT / 2, POD_KEYS_COUNT)}
        </Typography>
      </Box>
    </>
  );
}
