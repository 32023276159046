import api from "../../services/api";
import { analyticsTrackEvent } from "../../services/analytics";

export type UserPodRatingType = {
  id: string;
  rating: number;
  ratingDetails: string[];
  effects: string[];
};

/**
 * Create new pod rating using pod key
 */
export async function createUserPodRating(
  podKey: string,
  rating: number
): Promise<UserPodRatingType> {
  const response = await api.post(`/pas/user-rating`, {
    json: {
      podKey,
      rating
    }
  });

  if (response.ok) {
    analyticsTrackEvent("rating", { rating });
    const json = await response.json();
    return json as UserPodRatingType;
  } else {
    const json = await response.json();
    throw new Error(
      json.message || "Something went wrong trying to create the user rating."
    );
  }
}

/**
 * Update existing pod rating to add details or change 5 stars rating
 */
export async function updateUserPodRating(
  ratingId: string,
  rating: number,
  ratingDetails: string[],
  effects: string[]
) {
  const response = await api.put(`/pas/user-rating`, {
    json: {
      ratingUuid: ratingId,
      rating,
      details: ratingDetails,
      effects
    }
  });

  if (response.ok) {
    const json = await response.json();
    return json as UserPodRatingType;
  } else {
    const json = await response.json();
    throw new Error(
      json.message || "Something went wrong trying to create the user rating."
    );
  }
}
