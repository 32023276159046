import { Container, Box } from "@material-ui/core";
import React from "react";
import NotFound from "../../components/not-found";
import { RATING_BASE_URL } from "./constants";
import PodRatingForm from "./containers/PodRatingForm";
import { RouteComponentProps, Router } from "@reach/router";
import { navigate } from "gatsby";
import useLocalizedLocationPath from "../../hooks/use-localized-location-path";
import useIsAuthenticated from "../../hooks/use-is-authenticated";
import { getSignInPathWithRedirect } from "../../helpers/path";
import { StringParam, useQueryParam } from "use-query-params";
import { stringify } from "query-string";

type Props = RouteComponentProps<{ podKey: string }>;

/**
 * Full page Pod rating /rating/:podKey
 */
const PodRatingPage = ({ podKey }: Props) => {
  const isAuthenticated = useIsAuthenticated();
  const getLocalizedPath = useLocalizedLocationPath();
  const [ratingQueryParam] = useQueryParam("rating", StringParam);

  // Redirect to sign-in if user is not authenticated
  if (!isAuthenticated) {
    navigate(
      getLocalizedPath(
        getSignInPathWithRedirect(
          `${RATING_BASE_URL}/${podKey}?${stringify({
            rating: ratingQueryParam
          })}`
        )
      )
    );
    return null;
  }

  const handleOnComplete = () => {
    navigate(getLocalizedPath("/user/pods"));
  };

  return (
    <PodRatingForm
      podKey={podKey}
      onRatingComplete={handleOnComplete}
      onStep1Back={() => navigate(getLocalizedPath("/user/pods"))}
      customWrapper={({ children }) => (
        <Container maxWidth="sm">
          <Box
            height={{
              xs: "calc(var(--100vh) - var(--header-height))",
              md: "auto"
            }}
            paddingY={{ xs: 2, md: 4 }}
          >
            {children}
          </Box>
        </Container>
      )}
    />
  );
};

export default function RatingApp() {
  const getLocalizedPath = useLocalizedLocationPath();
  return (
    <Router basepath={getLocalizedPath(RATING_BASE_URL)}>
      <PodRatingPage path="/:podKey" />
      <NotFound default noLogo />
    </Router>
  );
}
