import { Box, Button, Hidden, styled, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { useState } from "react";
import Imgix from "react-imgix";
import BackButton from "../../../../../components/back-navigation/BackButton";
import useTranslate from "../../../../../hooks/use-translate";
import { ProductVariantType } from "../../../../../types/airgraft";
import PodKeyInfo from "../../../../activation/components/PodKeyInfo";
import { RATING_LABELS } from "../../../constants";

export const CustomPodKeyInfo = styled(PodKeyInfo)(({ theme }) => ({
  marginTop: "35px"
}));

type Props = {
  podKey: string;
  productVariant: ProductVariantType;
  onSubmit: (rating: number) => void;
  onBack?: () => void;
  previousRating: number;
};

export default function RatingStep1Rating({
  podKey,
  productVariant,
  onSubmit,
  onBack,
  previousRating
}: Props) {
  const t = useTranslate();
  const [rating, setRating] = useState<number>(previousRating || null);

  // Submit button
  let submitButtonText = t("continue");
  if (!!previousRating) {
    submitButtonText = t("rating.updateRating");
  }

  const handleSubmit = () => {
    if (rating) {
      onSubmit(rating);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      flex={1}
    >
      <div>
        {onBack && (
          <Box mb={{ xs: 1, md: 2 }}>
            <BackButton onClick={onBack}>{t("back")}</BackButton>
          </Box>
        )}

        {/* Title */}
        <Typography variant="h5">{t("rating.step1.title")}</Typography>
      </div>

      {/* Product + Rating */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={{ xs: 2, md: 4, xl: 6 }}
      >
        <Hidden mdUp>
          <Imgix
            src={productVariant.featuredImage.url}
            width={180}
            height={180}
            imgixParams={{
              ar: "1",
              auto: "format,compress"
            }}
          />
        </Hidden>
        <Hidden smDown>
          <Imgix
            src={productVariant.featuredImage.url}
            width={250}
            height={250}
            imgixParams={{
              ar: "1",
              auto: "format,compress"
            }}
          />
        </Hidden>

        <CustomPodKeyInfo podKey={podKey} />

        <Box mt={{ xs: 1, md: 2 }} textAlign="center">
          <Typography variant="h6">{productVariant.title}</Typography>
          <Typography variant="body1" color="textSecondary">
            {productVariant.brand.name}
          </Typography>
        </Box>

        {/* 5 stars rating widget */}
        <Box mt={{ xs: 1, md: 2 }}>
          <Rating
            name="rating"
            size="large"
            value={rating}
            onChange={(e, value) => setRating(value)}
          />

          {rating && (
            <Typography
              variant="body2"
              style={{
                visibility: rating ? "visible" : "hidden",
                textAlign: "center",
                minHeight: "2rem"
              }}
            >
              {t(`rating.labels.${RATING_LABELS[rating]}`)}
            </Typography>
          )}
        </Box>
      </Box>

      {/* Continue button */}
      <Box mt={{ xs: 2, md: 4, xl: 6 }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
        >
          {submitButtonText}
        </Button>
        <Box mt={{ xs: 1, md: 3 }} textAlign="center">
          <Typography variant="caption" color="textSecondary">
            {t("rating.improveExperience")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
