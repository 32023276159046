import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { useList } from "react-use";
import BackButton from "../../../../../components/back-navigation/BackButton";
import useTranslate from "../../../../../hooks/use-translate";
import RatingChip from "../../../components/RatingChip";
import { RATING_EFFECTS } from "../../../constants";
import { UserPodRatingType } from "../../../utils";

type Props = {
  onSubmit: (effects: string[]) => void;
  userRating: UserPodRatingType;
  onBack?: () => void;
};

export default function RatingStep3Effects({
  onSubmit,
  userRating,
  onBack
}: Props) {
  const t = useTranslate();
  const [effects, { push: pushEffect, removeAt: removeEffect }] = useList(
    userRating.effects || []
  );

  const handleChipClick = (effect: string) => {
    const index = effects.indexOf(effect);
    if (index >= 0) {
      removeEffect(index);
    } else {
      pushEffect(effect);
    }
  };

  // On Submit
  const handleSumbitClick = () => {
    onSubmit(effects);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      flex={1}
    >
      {/* Title */}
      <Box>
        <Box>
          {onBack && (
            <Box mb={{ xs: 1, md: 2 }}>
              <BackButton onClick={onBack}>{t("back")}</BackButton>
            </Box>
          )}

          <Typography variant="h5">{t("rating.step3.title")}</Typography>
        </Box>

        {/* Details chips */}
        <Box display="flex" flexWrap="wrap" mt={{ xs: 4, md: 6, lg: 6 }}>
          {RATING_EFFECTS.map(effect => (
            <RatingChip
              key={effect}
              onClick={() => handleChipClick(effect)}
              variant="NEUTRAL"
              active={effects.includes(effect)}
            >
              {t(`rating.effectLabels.${effect}`)}
            </RatingChip>
          ))}
        </Box>
      </Box>

      {/* Continue button */}
      <Box mt={{ xs: 4, md: 4, lg: 6 }} width="100%">
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSumbitClick}
        >
          {t("submit")}
        </Button>
      </Box>
    </Box>
  );
}
