import { NoSsr } from "@material-ui/core";
import { graphql } from "gatsby";
import React from "react";
import NoSsrPlaceholder from "../components/no-ssr-placeholder";
import Seo from "../components/seo";
import PodRatingApp from "../containers/rating";

export default function PodRatingPage({ data }: { data: Queries.RatingQueryDataQuery }) {
  return (
    <>
      <Seo
        seo={data.datoCmsWebPage?.seo}
        seoMetaTags={data.datoCmsWebPage?.seoMetaTags}
      />
      <NoSsr fallback={<NoSsrPlaceholder />}>
        <PodRatingApp />
      </NoSsr>
    </>
  );
}

export const query = graphql`
  query RatingQueryData($locale: String!) {
    datoCmsWebPage(path: { eq: "rating" }, locale: { eq: $locale }) {
      ...WebPageFragment
    }
  }
`;
