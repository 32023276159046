import { styled, Theme } from "@material-ui/core";
import React from "react";
import POSITIVE_ICON from "./icons/positive.svg";
import NEGATIVE_ICON from "./icons/negative.svg";
import NEUTRAL_ICON from "./icons/neutral.svg";
import ACTIVE_ICON from "./icons/active.svg";

type RatingChipVariantType = "POSITIVE" | "NEGATIVE" | "NEUTRAL";

const Chip = styled("div")(
  ({ theme, active }: { theme: Theme; active: boolean }) => ({
    display: "flex",
    alignItems: "center",
    height: "28px",
    lineHeight: "32px",
    borderRadius: "20px",
    backgroundColor: active
      ? theme.palette.primary.main
      : theme.palette.common.white,
    padding: "0 4px 0 12px",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontFamily: "Roobert, Arial",
    fontWeight: 600,
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    fontSize: "1.2rem",
    cursor: "pointer",
    "-webkit-tap-highlight-color": "rgba(255, 255, 255, 0)",
    userSelect: "none",
    transition: "background 150ms",
    "&:hover": {
      backgroundColor: active
        ? theme.palette.primary.main
        : theme.palette.grey[100]
    }
  })
);

const getChipIconBackgroundUrl = (
  variant: RatingChipVariantType,
  active: boolean
) => {
  if (active) {
    return ACTIVE_ICON;
  }
  return {
    POSITIVE: POSITIVE_ICON,
    NEGATIVE: NEGATIVE_ICON,
    NEUTRAL: NEUTRAL_ICON
  }[variant];
};

const ChipIcon = styled("div")(
  ({
    variant,
    active
  }: {
    variant: RatingChipVariantType;
    active: boolean;
  }) => ({
    width: "22px",
    height: "22px",
    borderRadius: "50%",
    marginLeft: "8px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "center",
    backgroundImage: `url("${getChipIconBackgroundUrl(variant, active)}")`
  })
);

type Props = {
  children: string;
  onClick?: () => void;
  variant: RatingChipVariantType;
  active?: boolean;
};

export default function RatingChip({
  children,
  active,
  variant,
  onClick
}: Props) {
  return (
    <Chip onClick={onClick} active={active}>
      {children}
      <ChipIcon variant={variant} active={active} />
    </Chip>
  );
}
