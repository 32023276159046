import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { useList } from "react-use";
import BackButton from "../../../../../components/back-navigation/BackButton";
import useTranslate from "../../../../../hooks/use-translate";
import RatingChip from "../../../components/RatingChip";
import { RATING_DETAILS } from "../../../constants";
import { UserPodRatingType } from "../../../utils";

type Props = {
  onSubmit: (ratingDetails: string[]) => void;
  userRating: UserPodRatingType;
  onBack?: () => void;
};

export default function RatingStep2Details({
  onSubmit,
  userRating,
  onBack
}: Props) {
  const t = useTranslate();
  const [ratingDetails, { push: pushDetails, removeAt: removeDetails }] =
    useList(userRating.ratingDetails || []);

  const handleDetailsChipClick = (detail: string) => {
    const index = ratingDetails.indexOf(detail);
    if (index >= 0) {
      removeDetails(index);
    } else {
      pushDetails(detail);
    }
  };

  // Dynamic chips based on rating
  let availableDetails = [];
  if (userRating.rating >= 5) {
    availableDetails = RATING_DETAILS.POSITIVE;
  } else if (userRating.rating >= 2 && userRating.rating <= 4) {
    availableDetails = [...RATING_DETAILS.POSITIVE, ...RATING_DETAILS.NEGATIVE];
  } else {
    availableDetails = RATING_DETAILS.NEGATIVE;
  }

  // On Submit
  const handleSumbitClick = () => {
    onSubmit(ratingDetails.filter(d => availableDetails.includes(d)));
  };

  const getRatingChipVariant = (detail: string) => {
    if (RATING_DETAILS.POSITIVE.includes(detail)) {
      return "POSITIVE";
    } else if (RATING_DETAILS.NEGATIVE.includes(detail)) {
      return "NEGATIVE";
    }
    return "NEUTRAL";
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      flex={1}
    >
      {/* Title */}
      <Box>
        <Box>
          {onBack && (
            <Box mb={{ xs: 1, md: 2 }}>
              <BackButton onClick={onBack}>{t("back")}</BackButton>
            </Box>
          )}

          <Typography variant="h5">{t("rating.step2.title")}</Typography>
          <Box mt={{ xs: 1, md: 2 }}>
            <Typography variant="body2">
              {t("rating.step2.subtitle")}
            </Typography>
          </Box>
        </Box>

        {/* Details chips */}
        <Box display="flex" flexWrap="wrap" mt={{ xs: 4, md: 6, lg: 6 }}>
          {availableDetails.map(detail => (
            <RatingChip
              key={detail}
              onClick={() => handleDetailsChipClick(detail)}
              variant={getRatingChipVariant(detail)}
              active={ratingDetails.includes(detail)}
            >
              {t(`rating.detailLabels.${detail}`)}
            </RatingChip>
          ))}
        </Box>
      </Box>

      {/* Continue button */}
      <Box mt={{ xs: 4, md: 4, lg: 6 }} width="100%">
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSumbitClick}
        >
          {t("submit")}
        </Button>
      </Box>
    </Box>
  );
}
