import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { NumberParam, useQueryParam } from "use-query-params";
import NotFound from "../../../../components/not-found";
import useActivatedPodHistory from "../../../../hooks/use-activated-pod-history";
import useCustomSnackbar from "../../../../hooks/use-custom-snackbar";
import useProductQuery from "../../../../hooks/use-product-query";
import useTranslate from "../../../../hooks/use-translate";
import {
  createUserPodRating,
  updateUserPodRating,
  UserPodRatingType
} from "../../utils";
import RatingStep1Rating from "./Step1Rating";
import RatingStep2Details from "./Step2Details";
import RatingStep3Effects from "./Step3Effects";

type Props = {
  podKey: string;
  onStep1Complete?: (rating: number) => void;
  onStep1Back?: () => void;
  onRatingComplete?: () => void;
  customWrapper?: React.ComponentType;
};

export default function PodRatingForm({
  podKey,
  customWrapper,
  onStep1Complete,
  onStep1Back,
  onRatingComplete
}: Props) {
  const t = useTranslate();
  const snackbar = useCustomSnackbar();
  const queryClient = useQueryClient();
  const [ratingQueryParam] = useQueryParam("rating", NumberParam);
  const Wrapper = customWrapper || React.Fragment;

  // Get user pod history
  const { data: podHistoryData, isLoading: isLoadingPodHistory } =
    useActivatedPodHistory();

  // Get pod history by podKey
  const podHistory = podHistoryData?.content?.find(
    item => item.podKey === podKey
  );
  const previousUserRating = podHistory?.userRating;

  // Get product by batchId
  const { data: product, isLoading: isLoadingProduct } = useProductQuery(
    { batchId: podHistory?.batchId },
    { enabled: !!podHistory }
  );
  const productVariant = product?.variants.find(v =>
    v.batchIds.includes(podHistory?.batchId)
  );

  // UI Step
  const [step, setStep] = useState<
    "STEP1_RATING" | "STEP2_DETAILS" | "STEP3_EFFECTS"
  >("STEP1_RATING");

  // User rating created or updated by form
  const [userRating, setUserRating] = useState<UserPodRatingType>(null);

  // Force "useActivatedPodHistory" cache reset
  const invalidatePodHistoryQuery = async () => {
    await queryClient.invalidateQueries("user-activated-pod-history");
  };

  /**
   * After user click "Continue" on first step, send data to api right away.
   */
  const handleStep1RatingSubmit = async (rating: number) => {
    try {
      // Create new rating or update previous rating by id
      let latestUserRating: UserPodRatingType = null;
      if (!!previousUserRating) {
        latestUserRating = await updateUserPodRating(
          previousUserRating.id,
          rating,
          [],
          []
        );
      } else {
        latestUserRating = await createUserPodRating(podKey, rating);
      }
      setUserRating(latestUserRating);

      // Refetch "useActivatedPodHistory" cache since it has ratings data
      invalidatePodHistoryQuery();
    } catch (e) {
      snackbar.showError(t("error.somethingWentWrong"));
      return;
    }

    if (onStep1Complete) {
      onStep1Complete(rating);
    }

    // Then show step 2 to get more details
    setStep("STEP2_DETAILS");
  };

  /**
   * After user click "Submit" on second step, update user rating with details
   */
  const handleStep2DetailsSubmit = async (ratingDetails: string[]) => {
    try {
      const updatedRating = await updateUserPodRating(
        userRating.id,
        userRating.rating,
        ratingDetails,
        []
      );
      setUserRating(updatedRating);
    } catch (e) {}

    invalidatePodHistoryQuery();

    if (userRating.rating > 1) {
      // Then show step 3 to get more details if user didnt rate 1 star
      setStep("STEP3_EFFECTS");
    } else if (onRatingComplete) {
      onRatingComplete();
      snackbar.showMessage(t("rating.submitted"));
    }
  };

  /**
   * After user click "Submit" on third step, update user rating with effects
   */
  const handleStep3EffectsSubmit = async (effects: string[]) => {
    try {
      const updatedRating = await updateUserPodRating(
        userRating.id,
        userRating.rating,
        userRating.ratingDetails,
        effects
      );
      setUserRating(updatedRating);
    } catch (e) {}

    invalidatePodHistoryQuery();

    if (onRatingComplete) {
      onRatingComplete();
    }

    snackbar.showMessage(t("rating.submitted"));
  };

  // Auto-submit rating if ?rating=X query param is present. (Url from email links)
  useEffect(() => {
    if (ratingQueryParam && ratingQueryParam >= 1 && ratingQueryParam <= 5) {
      handleStep1RatingSubmit(ratingQueryParam);
    }
  }, []);

  // Loading skeleton
  if (isLoadingPodHistory || isLoadingProduct) {
    return (
      <Wrapper>
        <Box display="flex" flexDirection="column">
          <Skeleton width="50%" height="32px" animation="wave" />
          <Skeleton width="100%" height="32px" animation="wave" />
          <Skeleton width="60%" height="32px" animation="wave" />
        </Box>
      </Wrapper>
    );
  }

  // Invalid pod key or cannot find product matching the pod key
  if (!podHistory || !product || !productVariant) {
    return <NotFound noLogo />;
  }

  return (
    <Wrapper>
      {step === "STEP1_RATING" && (
        <RatingStep1Rating
          podKey={podKey}
          productVariant={productVariant}
          onSubmit={handleStep1RatingSubmit}
          onBack={onStep1Back}
          previousRating={previousUserRating?.rating}
        />
      )}
      {step === "STEP2_DETAILS" && (
        <RatingStep2Details
          userRating={userRating}
          onSubmit={handleStep2DetailsSubmit}
          onBack={() => setStep("STEP1_RATING")}
        />
      )}
      {step === "STEP3_EFFECTS" && (
        <RatingStep3Effects
          userRating={userRating}
          onSubmit={handleStep3EffectsSubmit}
          onBack={() => setStep("STEP2_DETAILS")}
        />
      )}
    </Wrapper>
  );
}
